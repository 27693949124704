/* CircleBallsLoader.css */
@keyframes rotateCircle {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.circleBallsLoader {
    width: 18px;
    height: 18px;
    position: relative;
    animation: rotateCircle 1.5s linear infinite;
}

.ball {
    width: 2px;
    height: 2px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ball:nth-child(1) { transform: translate(-50%, -50%) rotate(0deg) translate(5px) rotate(0deg); }
.ball:nth-child(2) { transform: translate(-50%, -50%) rotate(45deg) translate(5px) rotate(-45deg); }
.ball:nth-child(3) { transform: translate(-50%, -50%) rotate(90deg) translate(5px) rotate(-90deg); }
.ball:nth-child(4) { transform: translate(-50%, -50%) rotate(135deg) translate(5px) rotate(-135deg); }
.ball:nth-child(5) { transform: translate(-50%, -50%) rotate(180deg) translate(5px) rotate(-180deg); }
.ball:nth-child(6) { transform: translate(-50%, -50%) rotate(225deg) translate(5px) rotate(-225deg); }
.ball:nth-child(7) { transform: translate(-50%, -50%) rotate(270deg) translate(5px) rotate(-270deg); }
.ball:nth-child(8) { transform: translate(-50%, -50%) rotate(315deg) translate(5px) rotate(-315deg); }
